import React, { useState, useEffect } from "react";
import VideoGrid from "../../components/VideoGrid";

const CountDown = ({ user, onClick }) => {
    const { amount = 0 } = user;
    const calculateTimeLeft = () => {
        const now = new Date();
        const targetDate = new Date('January 1, 2025 00:00:00');

        const difference = targetDate - now;

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    const timerComponents = [];

    const labels = {
        days: 'Días',
        hours: 'Horas',
        minutes: 'Minutos',
        seconds: 'Segundos',
    };

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span className="Header-text" key={interval}>
        {timeLeft[interval]} {labels[interval]}{" "}
      </span>
        );
    });

    return (
        <div className="CountDown">
            <div className="CountDown-text">
                <div>
                    <span className="Header-title">Lanzamiento</span>
                </div>
                <div>
                    <span className="Header-title">Primero de enero del 2025</span>
                </div>
                <div>
                    <span className="subtitle">Faltan</span>
                </div>
                <div className="Flex" style={{justifyContent: 'center'}}>
                  <div style={{backgroundColor: '#000', width: '40%', borderRadius: 20}}>
                    {timerComponents.length ? timerComponents : <span>Tiempo Terminado!</span>}
                  </div>
                </div>
                <div className="Flex" style={{justifyContent:'center', padding: 50}}>
                    <button disabled={amount === 0} className="Header-button" onClick={onClick} style={{zIndex: 1}}>
                        Enviar (PP)
                    </button>
                </div>
            </div>
            <VideoGrid />
        </div>
    );
}

export default CountDown;
