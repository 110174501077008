import React, { useState } from "react";
import {doc, updateDoc, serverTimestamp, setDoc} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebaseConfig";
import { useSnackbar } from "notistack";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import "react-phone-input-2/lib/style.css";

function SendCryptoModal({user, setUser, showModal, setShowModal}) {
    const { id, requested_withdrawal: requestedWithdrawal } = user;
    const { enqueueSnackbar } = useSnackbar();

    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');

    const handleClose = () => {
        setShowModal(false);
        setAddress('');
        setEmail('');
    }

    const addWithdrawalData = async () => {
        try {
            const docRef = doc(db, 'withdrawals', uuidv4());

            await setDoc(docRef, {
              user_id: id,
              address: address,
              email: email,
              timestamp: serverTimestamp(),
              has_completed: false,
            });

            await updateDoc(doc(db, 'users', id), {
                requested_withdrawal: true
            });
            enqueueSnackbar('¡Solicitud enviada! Estamos procesando su solicitud. Revise su correo para la confirmación.', {
                variant: 'success',
            });
            setUser({...user, requested_withdrawal: true})
        } catch (e) {
            enqueueSnackbar(`Ocurrió un error ${e}`, {
                variant: 'error',
            });
        } finally {
            setShowModal(false);
            setAddress('');
            setEmail('');
        }
    };

    const isValidateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const isValidSolanaAddress = address && address.length > 30 && !requestedWithdrawal;

    return (
        <>
            <Modal backdrop="static" show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>¿Transferir a wallet?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {requestedWithdrawal ? (
                        <div>
                            La solicitud para transferir los fondos a tu wallet está siendo procesada.
                        </div>
                    ): (
                        <>
                            <div>
                                Por favor, introduzca la dirección de la cuenta que va a recibir el depósito. Asegúrese de introducir correctamente la dirección de la cuenta.
                            </div>
                            <label style={{marginTop: 20, width: '100%'}}>
                                <div style={{marginBottom: 10}}>
                                    Dirección de Solana wallet:
                                </div>
                                <input
                                    disabled={requestedWithdrawal}
                                    type="text"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    placeholder="ingrese la dirección"
                                    style={{width: '100%'}}
                                />
                            </label>
                            <label style={{marginTop: 20, width: '100%'}}>
                                <div style={{marginBottom: 10}}>
                                    Correo electrónico:
                                </div>
                                <input
                                    disabled={requestedWithdrawal}
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="ingrese correo aquí"
                                    style={{width: '100%'}}
                                />
                            </label>
                            <div style={{marginTop: 15}}>
                              ¿Aún no tienes una wallet?
                            </div>
                          <div style={{marginTop: 15, fontWeight: 'bold'}}>
                            Descarga
                          </div>
                          <a
                            className="Flex"
                            target="_blank"
                            style={{padding: 10, justifyContent: 'center'}}
                            href="https://phantom.app/download">
                            <img
                              src={require('../../assets/images/wallet.jpg')}
                              alt="phantom wallet"
                              style={{width: '40%', borderRadius: 10}}
                            />
                          </a>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button
                        disabled={!isValidSolanaAddress || !isValidateEmail(email)}
                        variant="primary"
                        onClick={addWithdrawalData}>
                        Enviar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SendCryptoModal;
