import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyA-Gltn_6nPV1wk1yBabyl2gOJg580xMxE",
    authDomain: "pp-wallet-82da1.firebaseapp.com",
    projectId: "pp-wallet-82da1",
    storageBucket: "pp-wallet-82da1.appspot.com",
    messagingSenderId: "70878189946",
    appId: "1:70878189946:web:022b37aa43808fa66c5ec3",
    measurementId: "G-WB95LFHTRF"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const functions = getFunctions(app);

auth.settings.appVerificationDisabledForTesting = true;

export { auth, analytics, db, functions };
