import React from "react";

const Body = () => {
    return (
        <div className="Body">
            <div className="Body-text-wrapper">
                <span className="Header-title">¿Que son las MEME Coins?</span>
                <p className="Header-text">
                    Las meme coins son criptomonedas que se inspiran en memes o bromas de internet.
                    A menudo se crean con un propósito humorístico o satírico,
                    ellas han ganado popularidad y valor significativos dejando a muchos como multimillonarios.
                </p>
                <span className="Header-title">¿Como ganar dinero?</span>
                <p className="Header-text">
                  Esta moneda, como todas las meme coins, tiene el potencial de multiplicar tus ahorros, ya que con
                  la preventa tendrás la oportunidad exclusiva de comprar la moneda a un valor menor del que
                  saldrá a la venta. Y de ahí, el cielo es el límite.

                  En nuestra comunidad fanática de Peso Pluma, hay muchos inversionistas que tienen sus
                  corazones y sus billeteras en esta moneda. Así que no esperes más para ganar mucho dinero,
                  tal como hicieron muchos con Dogecoin (DOGE) y Shiba Inu (SHIB).
                </p>
                <span className="Header-title">¿Por qué compartir?</span>
                <p className="Header-text">
                  Al compartir esta información para que más personas se unan, causarás que el valor de la moneda
                  suba significativamente para el día del lanzamiento. ¿Qué esperas? Compártelo en todas tus redes
                  y sé parte de este gran corrido.
                </p>
            </div>
        </div>
    );
}

export default Body;
