import React from 'react';

const VideoGrid = () => {
    const videos = [
        {
            posterUrl: "https://cdn.prod.website-files.com/66730534922c83f433e78ea2%2F6674970eeefdc2f702116a62_BEHIND%20THE%20SCENES%20-%20PRAJIN%20%282%29-poster-00001.jpg",
            videoUrls: [
                "https://cdn.prod.website-files.com/66730534922c83f433e78ea2%2F6674970eeefdc2f702116a62_BEHIND%20THE%20SCENES%20-%20PRAJIN%20%282%29-transcode.mp4",
                "https://cdn.prod.website-files.com/66730534922c83f433e78ea2%2F6674970eeefdc2f702116a62_BEHIND%20THE%20SCENES%20-%20PRAJIN%20%282%29-transcode.webm",
            ],
            id: "dd2dd9f1-84d7-ed8d-20bb-0190c616f356-video"
        },
        {
            posterUrl: "https://cdn.prod.website-files.com/66730534922c83f433e78ea2%2F6674981056c84edfb6b246ab_BEHIND%20THE%20SCENES%20-%20PRAJIN%20%283%29-poster-00001.jpg",
            videoUrls: [
                "https://cdn.prod.website-files.com/66730534922c83f433e78ea2%2F6674981056c84edfb6b246ab_BEHIND%20THE%20SCENES%20-%20PRAJIN%20%283%29-transcode.mp4",
                "https://cdn.prod.website-files.com/66730534922c83f433e78ea2%2F6674981056c84edfb6b246ab_BEHIND%20THE%20SCENES%20-%20PRAJIN%20%283%29-transcode.webm",
            ],
            id: "dd2dd9f1-84d7-ed8d-20bb-0190c616f358-video"
        },
        {
            posterUrl: "https://cdn.prod.website-files.com/66730534922c83f433e78ea2%2F667498b6da594ed312599591_BEHIND%20THE%20SCENES%20-%20PRAJIN%20%284%29-poster-00001.jpg",
            videoUrls: [
                "https://cdn.prod.website-files.com/66730534922c83f433e78ea2%2F667498b6da594ed312599591_BEHIND%20THE%20SCENES%20-%20PRAJIN%20%284%29-transcode.mp4",
                "https://cdn.prod.website-files.com/66730534922c83f433e78ea2%2F667498b6da594ed312599591_BEHIND%20THE%20SCENES%20-%20PRAJIN%20%284%29-transcode.webm",
            ],
            id: "dd2dd9f1-84d7-ed8d-20bb-0190c616f35a-video"
        },
        {
            posterUrl: "https://cdn.prod.website-files.com/65ccdb9b3a0994655a108f14%2F6675888d1c78c037a10a3af4_BEHIND%20THE%20SCENES%20-%20PRAJIN%20%281%29-poster-00001.jpg",
            videoUrls: [
                "https://cdn.prod.website-files.com/65ccdb9b3a0994655a108f14%2F6675888d1c78c037a10a3af4_BEHIND%20THE%20SCENES%20-%20PRAJIN%20%281%29-transcode.mp4",
                "https://cdn.prod.website-files.com/65ccdb9b3a0994655a108f14%2F6675888d1c78c037a10a3af4_BEHIND%20THE%20SCENES%20-%20PRAJIN%20%281%29-transcode.webm",
            ],
            id: "dd2dd9f1-84d7-ed8d-20bb-0190c616f35c-video"
        }
    ];

    return (
        <div className="VideoGrid">
            {videos.map(video => (
                <div key={video.id} id={`w-node-${video.id}`}>
                    <div className="background-video-6 w-background-video w-background-video-atom">
                        <video
                            id={video.id}
                            autoPlay
                            loop
                            muted
                            playsInline
                            style={{ backgroundImage: `url(${video.posterUrl})` }}
                            data-wf-ignore="true"
                            data-object-fit="cover"
                        >
                            {video.videoUrls.map((url, index) => (
                                <source key={index} src={url} data-wf-ignore="true" />
                            ))}
                        </video>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default VideoGrid;
