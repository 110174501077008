import React  from "react";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import PayPalDonationButton from "../../components/PayPalDonationButton";

import "react-phone-input-2/lib/style.css";

function PurchaseModal({user, setUser, showModal, setShowModal, setShowCommentModal}) {
  const handleClose = () => setShowModal(false);

  return (
    <>
      <Modal backdrop="static" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>¿Cuánto desea comprar?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{marginBottom: 20, fontWeight: 'bold'}}>
            1 PP = 0.05MXN
          </div>
          <PayPalDonationButton
            setUser={setUser}
            user={user}
            setShowCommentModal={setShowCommentModal}
            setShowModal={setShowModal} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PurchaseModal;
