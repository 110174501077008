import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { BsInstagram, BsFacebook, BsTiktok, BsTwitterX } from "react-icons/bs";

const Footer = () => {
    const [comments, setComments] = useState([]);

    useEffect(() => {
        fetchCommentsData();
    }, [])

    const fetchCommentsData = async () => {
        const querySnapshot = await getDocs(collection(db, 'comments'));
        const docsData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setComments(docsData);
    };

    return (
        <footer>
            <span className="Header-title">Compradores</span>
            <div className="Flex" style={{justifyContent: 'center'}}>
                <div style={{maxWidth: '100%'}}>
                    {comments.length && (
                        comments.map(item =>
                            <div key={item.id} className="Footer-card">
                                <div className="Footer-text-wrapper">
                                    <div>
                                        <span className="Header-text" style={{fontWeight: 'bold'}}>{item.name}</span>
                                    </div>
                                    <div>
                                        <span className="Header-text" style={{color: '#bb725e'}}>{`Compra de $${item.amount}MXN`}</span>
                                    </div>
                                    <span className="Header-text">{item.comment}</span>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
            <div style={{padding: 100}}>
                <div style={{padding: 10}}>
                    <div className="Flex" style={{justifyContent: 'center'}}>
                        <BsFacebook fill="#fff" size={20} style={{margin: 10}} onClick={() => window.open('https://www.facebook.com/profile.php?id=61561801257642', '_blank')} />
                        <BsInstagram fill="#fff" size={20} style={{margin: 10}} onClick={() => window.open('https://www.instagram.com/peso.pluma.crypto', '_blank')} />
                        <BsTiktok fill="#fff" size={20} style={{margin: 10}} onClick={() => window.open('https://www.tiktok.com/@peso.pluma.crypto', '_blank')} />
                        <BsTwitterX fill="#fff" size={20} style={{margin: 10}} onClick={() => window.open('https://x.com/pesoplumacrypto', '_blank')} />
                    </div>
                </div>
                <span className="Header-text" style={{fontSize: 20}}>Derechos Reservados @ 2024</span>
                <div>
                  <span className="Header-title" style={{fontSize: 20}}>¡Síguenos en todas nuestras redes y participa en el sorteo para ganar 100,000 PP!</span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
