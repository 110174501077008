import React, { useState } from "react";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebaseConfig";
import { useSnackbar } from "notistack";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import "react-phone-input-2/lib/style.css";

function CommentModal({user, showModal, setShowModal}) {
    const { purchase_amount: purchaseAmount } = user;
    const { enqueueSnackbar } = useSnackbar();
    const [name, setName] = useState('');
    const [comment, setComment] = useState('');

    const handleClose = () => {
        setShowModal(false);
        setName('');
        setComment('');
    }

    const addCommentData = async () => {
        try {
            const docRef = doc(db, 'comments', uuidv4());

            await setDoc(docRef, {
                name: name || 'Desconocido',
                amount: purchaseAmount,
                comment: comment,
                timestamp: serverTimestamp(),
            });

            enqueueSnackbar('El commentario se ha publicado exitosamente.', {
                variant: 'success',
            });
        } catch (e) {
            enqueueSnackbar(`Ocurrió un error ${e}`, {
                variant: 'error',
            });
        } finally {
            setShowModal(false);
        }
    };

    return (
        <>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>¿Dejar Mensaje?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        ¡Felicitaciones por tu compra! A continuación, escribe un comentario
                        en la página para motivar a más personas a comprar. Recuerda que, entre
                        más personas compren, más subirá el valor de la moneda. Así que, comenta y comparte.
                    </div>
                    <label style={{marginTop: 20, width: '100%'}}>
                        <div style={{marginBottom: 10}}>
                            Nombre:
                        </div>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="ingresa comentario"
                            style={{width: '100%'}}
                        />
                    </label>
                    <label style={{marginTop: 20, width: '100%'}}>
                        <div style={{marginBottom: 10}}>
                            Comentario:
                        </div>
                        <textarea
                            type="text"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder="ingresa comentario"
                            style={{width: '100%'}}
                        />
                    </label>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button disabled={!comment} variant="primary" onClick={addCommentData}>
                        Comentar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CommentModal;
