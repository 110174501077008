import React, { useState, useEffect } from "react";
import axios from "axios";
import { MotionAnimate } from "react-motion-animate";
import { v4 as uuidv4 } from "uuid";
import { getDoc, doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import Banner from "../../components/Banner";
import Header from "../../components/Header";
import Body from "../../components/Body";
import CountDown from "../../components/CountDown";
import Footer from "../../components/Footer";
import SendCryptoModal from "../../components/SendCryptoModal";
import PurchaseModal from "../../components/PurchaseModal";
import CommentModal from "../../components/CommentModal";

const Landing = () => {
    const [showSendModal, setShowSendModal] = useState(false);
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const [user, setUser] = useState({});

    useEffect(() => {
        const id = localStorage.getItem('wallet_id');

        const validateId = async () => {
            if (id) {
                await fetchUserData(id);
            } else {
                await fetchIpAddressAndAddUser();
            }
        }

        validateId();
    }, []);

    const onSendButtonClick = () => {
        setShowSendModal(true);
    };

    const onPurchaseButtonClick = () => {
      setShowPurchaseModal(true);
    };

    const fetchUserData = async (id) => {
        try {
            const docRef = doc(db, 'users', id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setUser({...docSnap.data(), id: docSnap.id})
            } else {
                console.log('No such document!');
            }
        } catch (e) {
            console.error('Error fetching user document:', e);
        }
    };

    const fetchIpAddressAndAddUser = async () => {
        try {
            const response = await axios.get(`https://ipapi.co/json/`);
            const ipAddress = response.data.ip;
            const userDoc = {
                ip_address: ipAddress,
                user_agent: navigator.userAgent,
                platform: navigator.platform,
                language: navigator.language,
                screen_resolution: {
                    width: window.screen.width,
                    height: window.screen.height,
                },
                timestamp: serverTimestamp(),
            };
            await addUserData(userDoc);
        } catch (error) {
            console.error('Error fetching IP address:', error);
        }
    };

    const addUserData = async (userDoc) => {
        try {
            const userId = uuidv4();
            const docRef = doc(db, 'users', userId);

            await setDoc(docRef, userDoc);

            localStorage.setItem('wallet_id', docRef.id);
            setUser({...userDoc, id: docRef.id});
        } catch (e) {
            console.error('Error adding user document:', e);
        }
    };

    return (
        <div>
            <div className="Banner">
                <Banner user={user} onClick={onSendButtonClick} />
            </div>
            <div className="content">
                <MotionAnimate>
                    <Header onClick={onPurchaseButtonClick} />
                </MotionAnimate>
                <MotionAnimate>
                    <Body />
                </MotionAnimate>
                <MotionAnimate>
                    <CountDown user={user} onClick={onSendButtonClick} />
                </MotionAnimate>
                <MotionAnimate>
                    <Footer />
                </MotionAnimate>
            </div>
            <SendCryptoModal
              user={user}
              setUser={setUser}
              setShowModal={setShowSendModal}
              showModal={showSendModal}
            />
            <CommentModal user={user} setShowModal={setShowCommentModal} showModal={showCommentModal} />
            <PurchaseModal
              user={user}
              setUser={setUser}
              setShowModal={setShowPurchaseModal}
              showModal={showPurchaseModal}
              setShowCommentModal={setShowCommentModal} />
        </div>
    );
};

export default Landing;
