import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Landing from "./containers/Landing";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
     <Router>
         <div className="App">
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Landing />
            </SnackbarProvider>
         </div>
     </Router>
  );
}

export default App;
