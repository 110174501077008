import React from "react";

const Header = ({ onClick }) => {

    return (
        <header style={{ position: "relative", overflow: "hidden" }}>
            <video
                autoPlay
                loop
                muted
                playsInline
                data-wf-ignore="true"
                data-object-fit="cover"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                }}
            >
                <source
                    src="https://cdn.prod.website-files.com/66730534922c83f433e78ea2%2F667367eab80b239823009deb_Untitled%20design%20%2827%29-transcode.mp4"
                    type="video/mp4"
                    data-wf-ignore="true"
                />
                Your browser does not support the video tag.
            </video>
            <div className="Header" style={{ height: '100vh', zIndex: 1, position: "relative", backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                <img
                    className="PP"
                    src={require('../../assets/images/peso_pluma.png')}
                    alt="PP"
                />
                <div className="Header-text-wrapper">
                    <span className="Header-title">Peso Pluma (PP)</span>
                    <p className="Header-text">
                        Bienvenidos a la preventa exclusiva de la MEME Coin Peso Pluma (PP). Esta
                        es una nueva criptomoneda que promete aumentar su valor
                        significativamente en los próximos meses, ya que está creada sobre
                        el blockchain de Solana y en pre venta. Sé de los primeros en comprar ahora a
                        0.05 CENTAVOS y multiplica tu inversión.
                    </p>
                    <div className="Header-button-wrapper">
                        <button className="Header-button" onClick={onClick}>Comprar ahora!</button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
