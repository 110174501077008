import React from "react";

const Banner = ({user, onClick}) => {
    const { amount = 0 } = user;

    return (
        <div className="Banner-wrapper">
            <div className="Flex">
                <img className="SolanaLogo" src={require('../../assets/images/solana_logo.png')} alt="solana logo" />
                <p className="Banner-text">
                    Solana Network
                </p>
            </div>
            <button disabled={amount === 0} className="Flex" onClick={onClick} style={{backgroundColor: 'transparent', height: 60, borderRadius: 20}}>
                <img className="Avatar" src={require('../../assets/images/peso_pluma.png')} alt="avatar"/>
                <p className="Banner-text">
                    {`$ ${amount.toLocaleString()} PP`}
                </p>
            </button>
        </div>
    );
}

export default Banner;
